import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from "../../shared-functions.js"
import Logo from "./Logo.js";
import { Navbar, Nav, NavDropdown, Row, Col, Button } from 'react-bootstrap';
// import { List } from "@phosphor-icons/react";
import { ThemeContext } from "../../Theme.js";
import { ArrowUpRight } from '@phosphor-icons/react';

export default function TopNavbar(props) {

  const location = useLocation();
  let isPageWide = useMediaQuery('(min-width: 600px)');
  const { theme } = useContext(ThemeContext);
  // let subdomain = getSubDomain(window.location.host);

  return (
    <div style={{ position: 'fixed', top: 0, zIndex: 100, backgroundColor: '#F9F9F9', width: '100vw' }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, paddingTop: 6, paddingLeft: 14, paddingRight: 10, width: '100%' }}>
        <Logo width={120} />
        { isPageWide && false &&
          <Nav variant='underline' style={{ marginLeft: 20, marginTop: 0 }} className="mr-auto">
            <Nav.Link href="/explore" active={location.pathname === '/explore'}>Explore</Nav.Link>
            <Nav.Link href="/library" active={location.pathname === '/library' || location.pathname.startsWith('/chat')}>Library</Nav.Link>
            <Nav.Link href="/settings" active={location.pathname === '/settings'}>Settings</Nav.Link>
          </Nav>    
        }
       <Button variant="primary" href="https://cal.com/peakspace/30-min-demo" target="_blank" rel="noopener noreferrer">Let's talk  <ArrowUpRight weight="bold" size={18} style={{ paddingBottom: 3 }} /></Button>
    </Row>
    </div>
  )

}





