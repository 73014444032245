import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { ArrowUpRight, FigmaLogo, Code, UsersFour, ShareNetwork, ArrowDown } from "@phosphor-icons/react";
import { ThemeContext } from "./Theme.js";
import Marquee from "react-fast-marquee";
import Cal, { getCalApi } from "@calcom/embed-react";

export default function Home() {

  const navigate = useNavigate();
  const location = useLocation();
  let isPageWide = useMediaQuery('(min-width: 640px)');
  const { theme } = useContext(ThemeContext);

  const scheduleCallRef = useRef(null);

  const scrollEffect = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  useEffect(()=>{
	  (async function () {
		const cal = await getCalApi({"namespace":"comaker-labs-intro"});
		cal("ui", {"theme":"light","hideEventTypeDetails":false,"layout":"month_view"});
	  })();
	}, [])

  return (
    <Row style={{ justifyContent: 'center', overflowY: 'hidden' }}>
      <Col xs={12} sm={11} md={11} lg={11} xl={11} style={{ padding: 20 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginLeft: 0, marginRight: 0, minHeight: '50vh' }}>
          <Col xs={12} sm={12} md={10} lg={8} xl={8} style={{ padding: 10, maxWidth: 800, textAlign: 'center' }}>
            {/* <h6 style={{ marginTop: 10 }}>Hello, we are Comaker Labs</h6> */}
            <h1 style={{ marginTop: 10 }}>We build AI-powered workflow automation tools for businesses</h1>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
              <Col xs={12} sm={12} md={10} lg={10} xl={8} style={{ padding: 0 }}>
                <p className="large">We are a San Francisco-based agency that delivers exceptional value to businesses in health, climate, sports, HR, finance, and more.</p>
              </Col>
            </Row>
            <Button variant="primary" style={{ marginTop: 20 }} onClick={() => scrollEffect(scheduleCallRef)}>Let's talk <ArrowDown weight="bold" size={18} style={{ paddingBottom: 3 }} /></Button>
          </Col>
        </Row>

        { true &&
          <Marquee pauseOnHover={true} gradientColor={'#13103C'} style={{ marginTop: 60, marginBottom: 60 }}>
            <Image src="/assets/frontpage/logos/yahoo.png" height="30px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/ucsb.png" height="40px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/fuse.png" height="30px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/floro.png" height="26px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/molecule.png" height="36px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/nikos.png" height="36px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/waveform.png" height="26px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/unbound.png" height="30px" style={{ marginRight: 120 }} />
            <Image src="/assets/frontpage/logos/fanflow.png" height="36px" style={{ marginRight: 120 }} />
          </Marquee>
        }

        { false &&
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: 100, marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding: 10, textAlign: 'center' }}>
              <h6>What we do</h6>
              {/* <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
                <h6 style={{ color: '#00eeee', marginRight: 20 }}>#Mobile</h6>
                <h6 style={{ color: '#00eeee', marginRight: 20 }}>#SaaS</h6>
                <h6 style={{ color: '#00eeee', marginRight: 20 }}>#Health</h6>
                <h6 style={{ color: '#00eeee', marginRight: 20 }}>#Climate</h6>
                <h6 style={{ color: '#00eeee', marginRight: 20 }}>#Sports</h6>
                <h6 style={{ color: '#00eeee', marginRight: 20 }}>#Web3</h6>
                <h6 style={{ color: '#00eeee', marginRight: 20 }}>#Artificial Intelligence</h6>
              </Row> */}
            </Col>
          </Row>
        }

        { false &&
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: 80, marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} style={{ padding: 0 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: 40, marginBottom: 40, maxWidth: 400 }}>
                  <FigmaLogo size={32} color="var(--text-primary)" />
                  <h4 style={{ marginTop: 10 }}>Design</h4>
                  <p>We create beautiful UI designs and UX flows based on years of experience delivering products users love</p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: 40, marginBottom: 40, maxWidth: 400 }}>
                  <Code size={32} color="var(--text-primary)" />
                  <h4 style={{ marginTop: 10 }}>Develop</h4>
                  <p>Our team builds both mobile and web products in quick, iterative sprints while learning along the way</p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: 40, marginBottom: 40, maxWidth: 400 }}>
                  <UsersFour size={32} color="var(--text-primary)" />
                  <h4 style={{ marginTop: 10 }}>Grow</h4>
                  <p>We help brainstorm and execute product-led growth strategies to optimize acquisition funnels and increase retention</p>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: 40, marginBottom: 40, maxWidth: 400 }}>
                  <ShareNetwork size={32} color="var(--text-primary)" />
                  <h4 style={{ marginTop: 10 }}>Network</h4>
                  <p>We introduce you to our network of brilliant product, marketing, and design people who have helped countless teams like yours succeed</p>
                </Col>
              </Row>
            </Col>
          </Row>
        }

        { false &&
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 100, marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={12} lg={10} xl={9} style={{ padding: 10, maxWidth: 600 }}>
              <h6>Areas of interest</h6>
            </Col>
          </Row>
        }

        { false &&
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40, marginLeft: 0, marginRight: 0 }}>
            <h4 style={{ color: 'var(--text-primary)', backgroundColor: 'var(--text-primary)11', padding: '10px 30px', marginRight: 20, marginBottom: 20 }}>SaaS</h4>
            <h4 style={{ color: 'var(--text-primary)', backgroundColor: 'var(--text-primary)11', padding: '10px 30px', marginRight: 20, marginBottom: 20 }}>Health Tech</h4>
            <h4 style={{ color: 'var(--text-primary)', backgroundColor: 'var(--text-primary)11', padding: '10px 30px', marginRight: 20, marginBottom: 20 }}>Climate Tech</h4>
            <h4 style={{ color: 'var(--text-primary)', backgroundColor: 'var(--text-primary)11', padding: '10px 30px', marginRight: 20, marginBottom: 20 }}>Artificial Intelligence</h4>
            <h4 style={{ color: 'var(--text-primary)', backgroundColor: 'var(--text-primary)11', padding: '10px 30px', marginRight: 20, marginBottom: 20 }}>Sports</h4>
            <h4 style={{ color: 'var(--text-primary)', backgroundColor: 'var(--text-primary)11', padding: '10px 30px', marginRight: 20, marginBottom: 20 }}>UX</h4>
            <h4 style={{ color: 'var(--text-primary)', backgroundColor: 'var(--text-primary)11', padding: '10px 30px', marginRight: 20, marginBottom: 20 }}>HR</h4>
          </Row>
        }

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 80, marginLeft: 0, marginRight: 0 }}>
          <Col xs={12} sm={12} md={12} lg={10} xl={9} style={{ padding: 10, textAlign: 'center' }}>
            <h6>Our past projects</h6>
          </Col>
        </Row>

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: 40, marginLeft: 0, marginRight: 0 }}>
          <Col xs={11} sm={10} md={8} lg={6} xl={6} style={{ marginBottom: 40 }}>
            <a href="https://www.terastack.ai" target="_blank">
              <div style={{ backgroundColor: 'var(--bg-secondary)', textAlign: 'center', height: 680 }}>
                <Image src="/assets/frontpage/terastack.png" style={{ height: 680, maxWidth: '100%', objectFit: 'contain' }} />
              </div>
              <h4 style={{ marginTop: 10 }}>Terastack <ArrowUpRight weight="bold" size={18} style={{ paddingBottom: 0 }} /></h4>
              <p style={{ marginTop: -5 }}>Your AI-powered research and writing assistant</p>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
                <p className="small muted" style={{ marginRight: 20 }}>#ai</p>
                <p className="small muted" style={{ marginRight: 20 }}>#writing</p>
                <p className="small muted" style={{ marginRight: 20 }}>#web</p>
                <p className="small muted" style={{ marginRight: 20 }}>#saas</p>
              </Row>
            </a>
          </Col>
          <Col xs={11} sm={10} md={8} lg={6} xl={6} style={{ marginBottom: 40 }}>
            <div style={{ backgroundColor: 'var(--bg-secondary)', textAlign: 'center', height: 680 }}>
              <Image src="/assets/frontpage/molecule.png" style={{ height: 680, maxWidth: '100%', objectFit: 'contain' }} />
            </div>
            <h4 style={{ marginTop: 10 }}>Molecule Climate</h4>
            <p style={{ marginTop: -5 }}>The sustainability platform for climate-forward businesses</p>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
              <p className="small muted" style={{ marginRight: 20 }}>#climate</p>
              <p className="small muted" style={{ marginRight: 20 }}>#web</p>
              <p className="small muted" style={{ marginRight: 20 }}>#saas</p>
            </Row>
          </Col>
          <Col xs={11} sm={10} md={8} lg={6} xl={6} style={{ marginBottom: 40 }}>
            <a href="https://apps.apple.com/us/app/waveform-brain-music/id1547924757" target="_blank">
              <div style={{ backgroundColor: 'var(--bg-secondary)', textAlign: 'center', height: 680 }}>
                <Image src="/assets/frontpage/waveform.png" style={{ height: 660, maxWidth: '100%', objectFit: 'cover' }} />
              </div>
              <h4 style={{ marginTop: 10 }}>Waveform <ArrowUpRight weight="bold" size={18} style={{ paddingBottom: 0 }} /></h4>
              <p style={{ marginTop: -5 }}>A brain stimulation & relaxation app</p>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
                <p className="small muted" style={{ marginRight: 20 }}>#mobile</p>
                <p className="small muted" style={{ marginRight: 20 }}>#health</p>
              </Row>
            </a>
          </Col>
          <Col xs={11} sm={10} md={8} lg={6} xl={6} style={{ marginBottom: 40 }}>
            <a href="https://www.fanflow.xyz" target="_blank">
              <div style={{ backgroundColor: 'var(--bg-secondary)', textAlign: 'center', height: 680 }}>
                <Image src="/assets/frontpage/fanflow.png" style={{ height: 660, maxWidth: '100%', objectFit: 'cover' }} />
              </div>
              <h4 style={{ marginTop: 10 }}>Fanflow <ArrowUpRight weight="bold" size={18} style={{ paddingBottom: 0 }} /></h4>
              <p style={{ marginTop: -5 }}>A fan loyalty and crypto rewards platform</p>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
                <p className="small muted" style={{ marginRight: 20 }}>#mobile</p>
                <p className="small muted" style={{ marginRight: 20 }}>#web3</p>
                <p className="small muted" style={{ marginRight: 20 }}>#crypto</p>
                <p className="small muted" style={{ marginRight: 20 }}>#sports</p>
              </Row>
            </a>
          </Col>
          <Col xs={11} sm={10} md={8} lg={6} xl={6} style={{ marginBottom: 40 }}>
            <div style={{ backgroundColor: 'var(--bg-secondary)', textAlign: 'center', height: 680 }}>
              <Image src="/assets/frontpage/bloom.png" style={{ height: 680, maxWidth: '100%', objectFit: 'contain' }} />
            </div>
            <h4 style={{ marginTop: 10 }}>Bloom</h4>
            <p style={{ marginTop: -5 }}>A remote UX research platform</p>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
              <p className="small muted" style={{ marginRight: 20 }}>#web</p>
              <p className="small muted" style={{ marginRight: 20 }}>#saas</p>
              <p className="small muted" style={{ marginRight: 20 }}>#ux</p>
              <p className="small muted" style={{ marginRight: 20 }}>#research</p>
            </Row>
          </Col>
          <Col xs={11} sm={10} md={8} lg={6} xl={6} style={{ marginBottom: 40 }}>
            <a href="https://www.nikos.ai" target="_blank">
              <div style={{ backgroundColor: 'var(--bg-secondary)', textAlign: 'center', height: 680 }}>
                <Image src="/assets/frontpage/nikos.png" style={{ height: 680, maxWidth: '100%', objectFit: 'contain' }} />
              </div>
              <h4 style={{ marginTop: 10 }}>Nikos <ArrowUpRight weight="bold" size={18} style={{ paddingBottom: 0 }} /></h4>
              <p style={{ marginTop: -5 }}>A video testimonials platform</p>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
                <p className="small muted" style={{ marginRight: 20 }}>#web</p>
                <p className="small muted" style={{ marginRight: 20 }}>#saas</p>
                <p className="small muted" style={{ marginRight: 20 }}>#marketing</p>
              </Row>
            </a>
          </Col>
          {/* <Col xs={11} sm={10} md={8} lg={6} xl={6} style={{ marginBottom: 40 }}>
            <div style={{ backgroundColor: 'var(--bg-secondary)', textAlign: 'center', height: 680 }}>
              <Image src="/assets/frontpage/fuse.png" style={{ height: 680, maxWidth: '100%', objectFit: 'contain' }} />
            </div>
              <h4 style={{ marginTop: 10 }}>Fuse</h4>
              <p style={{ marginTop: -5 }}>Your AI-powered newsletter agent that delivers</p>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
                <p className="small muted" style={{ marginRight: 20 }}>#ai</p>
                <p className="small muted" style={{ marginRight: 20 }}>#email</p>
                <p className="small muted" style={{ marginRight: 20 }}>#web</p>
                <p className="small muted" style={{ marginRight: 20 }}>#saas</p>
              </Row>
          </Col> */}
        </Row>

        <Row ref={scheduleCallRef} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 80, marginLeft: 0, marginRight: 0 }}>
          <Col xs={12} sm={12} md={12} lg={10} xl={9} style={{ padding: 10, textAlign: 'center' }}>
            <h2>Schedule a call</h2>
          </Col>
        </Row>

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
            <Cal namespace="30-min-demo"
              calLink="peakspace/30-min-demo"
              style={{ width: "100%", height: "100%", overflow: "scroll" }}
              config={{ "layout": "month_view", "theme": "light" }}
            />
          </Col>
        </Row>

      </Col>
      <Footer />
    </Row>
  )



}
